<template>
<div>
  <div class="header-buttons">
    <ModuleFormButton
      :title="$t('buttons.save')"
      :required-permissions="requiredPermissions.saveButton"
      :disabled="callingApiCount > 0"
      @click="save"
    />
    <ModuleFormButton
      icon="close"
      @click="$router.push('/beUser')"
    />
  </div>
  <div class="form-body">
    <div class="user-details">
      <div class="user-details__headline">
        {{ $t('beUser.details') }}
      </div>
      <div class="user-details__body">
        <Input
          v-model="beUser.givenName"
          id="beUser_givenName"
          disabled
          :label="$t('beUser.firstName')"
        />
        <Input
          v-model="beUser.familyName"
          id="beUser_familyName"
          disabled
          :label="$t('beUser.lastName')"
        />
        <Input
          v-model="beUser.email"
          @blur="$v.beUser.email.$touch()"
          :error="$v.beUser.email.$error"
          id="beUser_email"
          disabled
          :label="$t('beUser.login')"
        />
        <!-- TODO CMS-2038 -->
        <Select
          v-if="false"
          v-model="beUser.department"
          :options="departmentsBySite"
          optionTitle="name"
          id="beUser_department"
          :label="$t('beUser.department')"
        />
        <!-- TODO CMS-2038 -->
        <OutlinedCheckbox
          v-if="false"
          class="user-details__body__one-row"
          v-model="beUser.enabled"
          id="beUser_enabled"
          :label="$t('beUser.enabled')"
        />
        <!-- Temporarily disabled as this probably won't be stored in SSO -->
        <Input
          v-if="false"
          v-model="beUser.jobTitle"
          id="beUser_jobTitle"
          :label="$t('beUser.jobTitle')"
        />
        <Input
          v-if="false"
          v-model="beUser.employmentType"
          id="beUser_employmentType"
          :label="$t('beUser.employmentType')"
        />
      </div>
    </div>
    <div class="user-details">
      <div class="user-details__headline">
        {{ $t('beUser.settings') }}
      </div>
      <div class="user-details__body">
        <!-- TODO CMS-2038 -->
        <Select
          v-if="false"
          v-model="beUser.defaultSite"
          :options="allSites"
          id="beUser_defaultSite"
          :label="$t('beUser.defaultSite')"
          @change="loadSiteDefiningItems(true)"
        />
        <!-- TODO CMS-2038 -->
        <div v-if="false">
          <label for="beUser_defaultRubric">{{ $t('beUser.defaultRubric') }}</label>
          <div class="form-group">
            <select
              v-model="beUser.defaultRubric"
              id="beUser_defaultRubric"
              class="form-control"
            >
              <option
                v-for="(rubric, index) in rubricsBySite"
                :key="`item-${index}`"
                :value="rubric.id"
                :selected="rubric.id == beUser.defaultRubric"
              >
                <template v-if="rubric.lvl === 1">&nbsp;&nbsp;&nbsp;</template>
                <template v-if="rubric.lvl === 2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</template>
                {{ rubric.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="user-details__body__one-row">
          <div class="title-text">
            {{ $t('beUser.defaultPermissionGroup') }}
          </div>
          <MultiSelect
            v-model="beUser.groups"
            :options="allPermissionGroups"
            :disabled="callingApiCount > 0"
            label="name"
            id="beUser_groups"
            track-by="groupId"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- we need to wait for the user and it's permissions to load and only then start rendering permissions table -->
  <BeUserPermissionTable
    v-if="userLoaded"
    ref="permissionTable"
    :selected-groups="beUser.groups"
    :selected-capabilities="beUser.capabilities"
  />
</div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import BeUserModel from '@/model/beUser/BeUser'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import BeUserPermissionTable from '@/components/beUser/BeUserPermissionTable'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import { mapState } from 'vuex'

export default {
  name: 'BeUserEditView',
  data () {
    return {
      beUser: this._.cloneDeep(BeUserModel),
      userLoaded: false, // we need to wait for the user and it's permissions to load and only then start rendering permissions table
      rubricsBySite: [],
      departmentsBySite: [],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_BACKEND_USER_PERMISSIONS
    }
  },
  validations: {
    beUser: {
      email: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      }
    }
  },
  components: {
    OutlinedCheckbox,
    ModuleFormButton,
    Input,
    Select,
    MultiSelect,
    BeUserPermissionTable
  },
  computed: {
    ...mapState([
      'callingApiCount'
    ]),
    allSites () {
      return this.$store.getters['site/all']
    },
    allPermissionGroups () {
      return this.$store.getters['group/list']
    }
  },
  methods: {
    loadingOn () {
      this.$store.commit('LOADING_ADD')
    },
    loadingOff () {
      this.$nextTick(() => this.$store.commit('LOADING_REMOVE'))
    },
    async getBeUser () {
      this.loadingOn()
      await this.$store.dispatch('beUser/fetchOne', this.$route.params.id)
        .then(() => {
          this.beUser = this.$store.getters['beUser/detail']
          // this.loadSiteDefiningItems(false) // TODO uncomment when we have rubrics and department ready
          this.userLoaded = true
          this.loadingOff()
        })
        .catch((error) => {
          console.error(error)
          this.loadingOff()
        })
    },
    loadSiteDefiningItems (resetItems) {
      if (resetItems) {
        this.beUser.defaultRubric = null
        this.beUser.department = null
      }
      this.loadRubricsBySite()
      this.loadDepartmentsBySite()
    },
    loadRubricsBySite () {
      this.rubricsBySite = this.$store.getters['rubric/rubricsBySite'](this.beUser.defaultSite)
    },
    loadDepartmentsBySite () {
      this.departmentsBySite = this.$store.getters['department/departmentsBySite'](this.beUser.defaultSite)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('beUser.error.required_fields'))
        return
      }

      // Update user (we never create user, only update)
      this.beUser.capabilities = this.$refs.permissionTable.getCapabilitiesForSave()
      const groups = this.beUser.groups.map(group => group.groupId)
      const beToUpdate = { ...this.beUser, groups }
      this.loadingOn()
      this.$store.dispatch('beUser/update', beToUpdate)
        .then(() => {
          if (this.$store.getters['beUser/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('beUser.message.updated_record'))
            this.$router.push('/beUser/' + this.beUser.userId + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['beUser/error'])
          }
          this.loadingOff()
        })
        .catch(error => {
          console.error(error)
          this.loadingOff()
        })
    }
  },
  mounted () {
    this.loadingOn()
    this.getBeUser()
    this.$store.dispatch('group/fetch', { all: true })
    this.loadingOff()
  }
}
</script>

<style lang="scss" scoped>
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.header-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}
.form-body {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  .user-details {
    background-color: #FFFFFF;
    border: rem(1px) solid  rgba(0,0,0,0.125);
    border-radius: rem(4px);
    box-shadow: 0rem rem(5px) rem(20px) rgba(0,0,0,0.05);
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(7) {
      flex: 0 0 49%;
    }
    &__headline {
      margin-bottom: 1rem;
    }
    &__body {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      div:not(.user-details__body__one-row) {
        @include bp(0 7) {
          flex: 0 0 100%;
        }
        @include bp(7) {
          flex: 0 0 49%;
        }
      }
      &__one-row {
        flex: 0 0 100%;
      }
      &__one-row.checkbox {
        margin-top: 2.65rem;
      }
    }
  }
}
</style>
